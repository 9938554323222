import { Card, Stack, Button, Accordion, Badge } from "react-bootstrap";
import { QRCode } from "react-qr-code";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faApple, faAndroid } from "@fortawesome/free-brands-svg-icons";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";

function AppCard({ id, title, date, version, platform }) {
    function variant(platform) {
        if (platform === "android") {
            return "success";
        }

        return "primary";
    }

    function icon(platform) {
        if (platform === "android") {
            return faAndroid;
        } else if (platform === "ios") {
            return faApple;
        }

        return faQuestion;
    }

    function link(platform, id) {
        return `https://${window.location.hostname}/apps/${platform}/${id}`;
    }

    return (
        <div className="col-md-4 mb-3" key={id}>
            <Card>
                <Card.Header>
                    <Stack direction="horizontal" className="d-flex justify-content-between">
                        <strong>{title}</strong>
                        <Button href={link(platform, id)} variant={variant(platform)}>
                            <Stack direction="horizontal" gap={1}>
                                <FontAwesomeIcon icon={icon(platform)}/>
                                Install
                            </Stack>
                        </Button>
                    </Stack>
                </Card.Header>

                <Card.Body>
                    <Accordion>
                        <Accordion.Item eventKey={id}>
                            <Accordion.Header>
                                QR Code
                            </Accordion.Header>

                            <Accordion.Body className="d-flex justify-content-center">
                                <QRCode value={link(platform, id)} />
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Card.Body>

                <Card.Footer className="d-flex justify-content-between">
                    <Badge pill bg="secondary">{date}</Badge>
                    <Badge pill bg="secondary">{version}</Badge>
                </Card.Footer>
            </Card>
        </div>
    );
}

export default AppCard;
