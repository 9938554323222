import { useState, useEffect } from "react";
import { Container, Stack, Row } from "react-bootstrap";
import AppCard from "./AppCard";

function AppList() {
    const [appsData, setAppsData] = useState(null);
    function loadApps() {
        setAppsData(null);
        fetch(process.env.REACT_APP_BASE_URL + "/apps")
            .then((response) => {
                console.log(response);
                return response.json();
            })
            .then((json) => setAppsData(json));
    }

    useEffect(() => {
        loadApps();
    }, []);

    return (
        <Container>
            <Stack direction="vertical">
                <Stack direction="horizontal" className="mt-3 mb-3">
                    <h1>Available Apps</h1>
                </Stack>

                <Row>
                    {appsData?.map(({ id, title, date, version, platform }) => (
                        <AppCard
                            id={id}
                            title={title}
                            date={date}
                            version={version}
                            platform={platform} />
                    ))}
                </Row>
            </Stack>
        </Container>
    );
}

export default AppList;
